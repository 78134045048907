<!--
 * @Author: Jin Biao
 * @Date: 2021-05-13 14:45:02
 * @LastEditTime: 2023-02-18 10:54:10
 * @Description:  
-->
<template>
  <div class="content">
    <div class="bg">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-circle-plus-outline" class="mr20" @click="$refs.formDialog.formVisible = true">添加分组</el-button>
      </div>
      <!-- 列表 -->
      <el-table :data="list" height="calc(100vh - 200px)" @sort-change="changeTableSort">
        <el-table-column prop="name" label="分组" align="left"></el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <div v-if="scope.$index < 2">不可更改</div>
            <el-button type="primary" v-if="scope.$index > 1" icon="el-icon-edit" @click="edit(scope.row)">编辑</el-button>
            <el-button type="danger" v-if="scope.$index > 1" icon="el-icon-delete" @click.native.prevent="toDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 新增、编辑 -->
      <roles-manage ref="formDialog" @refreshData="loadList"></roles-manage>
    </div>
  </div>
</template>

<script>
import rolesManage from './roles-manage'
import http from '@/utils/cloud'
import global from '@/config/global'

export default {
  components: {
    rolesManage
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.loadList()
  },
  methods: {
    async loadList() {
      const response = await http.POST('/jw-admin', {
        module: 'admin',
        operation: 'getRoles'
      })
      console.log(response)
      this.list = response.data
      this.loaded = true
    },
    /* 删除 */
    toDelete(index, item) {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      this.$confirm(`是否要删除分组：${item.name}`, '删除提示', {
        confirmButtonText: '删除',
        type: 'warning'
      })
        .then(async () => {
          const response = await http.POST('/jw-admin', {
            module: 'admin',
            operation: 'deleteRoles',
            id: item._id
          })

          if (response.status === 1) {
            this.$message.success('删除成功')
            this.list.splice(index, 1)
          } else {
            this.$message.error(response.msg)
          }
        })
        .catch(() => {})
    },
    /* 编辑 */
    edit(item) {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      this.$refs.formDialog.formData = JSON.parse(JSON.stringify(item))
      this.$refs.formDialog.formVisible = true
    }
  }
}
</script>

<style scoped lang="scss"></style>
